import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  author as AuthorPropType,
  person as PersonPropType,
} from 'lib/CustomPropTypes';

import { generateElementKey } from 'lib/stringUtils';
import { ChatCardBylineName } from 'components/ChatCard/ChatCardBylineName';
import { ChatCardBylineThumbnail } from '../ChatCardBylineThumbnail';

import styles from './styles.module.scss';

/**
 * @param {object} props
 * @param {(Author | Person)[]} props.authors
 * @param {number} props.position
 *
 */
const ChatCardByline = ({
  author,
  position,
  classNames,
}) => {
  if (!author) {
    return null;
  }

  // Account for different anatomies of the person object
  /** @type {Person} */
  const authorForThumbnail = get(author, 'person', author);
  const person = get(author, 'person', author);
  const name = get(person, 'name', null);
  const url = get(person, 'url.primary', null);
  const key = generateElementKey(name);

  const content = (
    <div
      className={cx(styles.chatCartByline, classNames)}
      data-testid="chat-card-byline-container"
    >
      <ChatCardBylineThumbnail
        author={authorForThumbnail}
        position={position}
        isLinked={false}
        key={`byline-thumbnail${author.id}`}
      />
      <div className={styles.inner}>
        <p
          className={styles.authors}
          data-testid="chat-card-byline"
        >
          <ChatCardBylineName
            key={key}
            person={person}
            additionalClasses={styles.chatBlogBylineName}
            position={position}
            isLinked={false}
          />
        </p>
      </div>
    </div>
  );

  return (
    url ? (
      <a
        href={url}
        className={cx(styles.bylineLink, classNames)}
        data-testid="chat-card-byline-link"
      >
        {content}
      </a>
    ) : (
      content
    )
  );
};

ChatCardByline.propTypes = {
  author: AuthorPropType || PersonPropType,
  position: PropTypes.number.isRequired,
  classNames: PropTypes.string,
};

ChatCardByline.defaultProps = {
  author: null,
  classNames: null,
};

export { ChatCardByline };
