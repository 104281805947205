import get from 'lodash.get';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Head from 'next/head';

import {
  person as PersonPropType,
} from 'lib/CustomPropTypes';
import getWorkbenchByline from 'lib/getWorkbenchByline';
import { LinkIfHref } from 'components/Link';

import styles from './styles.module.scss';

const block = 'chatCardByline';

export const ChatCardBylineName = ({
  person,
  additionalClasses,
  position,
  showShortBio,
  isLinked,
}) => {
  const name = get(person, 'name', null);
  const liveBlogShortBio = get(person, 'liveBlogShortBio', null);
  const showLiveBlogShortBio = showShortBio && liveBlogShortBio;

  if (!name) {
    return null;
  }

  const url = get(person, ['url', 'primary'], null);
  const nameTrim = name.trim();

  let authorContent = (
    <span
      className={classNames(styles[block], additionalClasses)}
      data-test={block}
      data-testid={`${block}-name`}
    >
      <span className={styles[`${block}--bio`]}>
        <span className={styles[`${block}--name`]}>
          <LinkIfHref
            href={isLinked ? url : undefined}
            tabIndex={isLinked ? -1 : undefined}
          >
            {nameTrim}
          </LinkIfHref>
        </span>
        {showLiveBlogShortBio && (
          <span className={styles[`${block}--shortbio`]}>
            {liveBlogShortBio}
          </span>
        )}
      </span>

    </span>
  );

  if (name.startsWith('<component')) {
    authorContent = getWorkbenchByline(name);
  }

  return (
    <>
      <Head>
        <meta name={`branch:deeplink:authorName${position}`} content={nameTrim} />
      </Head>

      {authorContent}
    </>
  );
};


ChatCardBylineName.propTypes = {
  person: PersonPropType,
  additionalClasses: PropTypes.string,
  position: PropTypes.number.isRequired,
  showShortBio: PropTypes.bool,
  isLinked: PropTypes.bool,
};

ChatCardBylineName.defaultProps = {
  person: {},
  additionalClasses: '',
  showShortBio: true,
  isLinked: false,
};
