import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'components/Link';
import { RunExperiment } from 'components/RunExperiment';
import buildAffiliateLink from 'lib/buildAffiliateLink';
import { stub as $t } from '@nbcnews/analytics-framework';

import { isLengthGreaterThan8 } from 'lib/fontBasedOnTextLength';
import { getDateTime } from 'lib/DateTime';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { isOnSale } from 'lib/productOfferPriceHelpers';

import { UNIVERSAL_CHECKOUT_ENABLED } from 'lib/brandFeatures';
import { withCookies, Cookies } from 'react-cookie';
import ShopButton from './ShopButton';

import './styles.themed.scss';


const ENCODER_STOP_DATE = 'Wed Apr 15 2020 00:00:00';

/**
 * Manages the button type to render.
 * @param {object} props
 * @param {string} props.productId
 * @param {string} props.name
 * @param {string} props.listPrice
 * @param {string} props.salePrice
 * @param {string} props.productUrl
 * @param {string} props.seller
 * @param {object} props.additionalStyles
 * @param {string} props.block
 * @param {bool} props.isLink
 * @param {string} props.articlePublishDate
 * @param {string} props.vertical
 * @param {string} props.target
 * @param {string} props.trackingCode
 * @param {object} props.cookies
 * @param {object} context
 * @param {string} context.canonicalUrl
 */
const ShopButtonInfo = (
  {
    productId,
    name,
    listPrice,
    salePrice,
    productUrl,
    seller,
    additionalStyles,
    block,
    isLink,
    articlePublishDate,
    vertical,
    target,
    trackingCode,
    cookies,
  }, { canonicalUrl },
) => {
  const universalCheckoutEnabled = getFeatureConfigForBrand(
    UNIVERSAL_CHECKOUT_ENABLED,
    vertical,
  );

  const [variation, setVariation] = useState(null);
  const selectButtonRedesign = '_vis_opt_exp_1407_combi';
  const cookieValue = cookies.get(selectButtonRedesign);

  useEffect(() => {
    if (vertical !== 'select') return;

    const params = new URLSearchParams(document.location.search);
    const prodVariation = params.get('prodVariation');

    if (cookieValue === '2' || prodVariation === '2') {
      setVariation('2');
    }
  }, [cookies]);

  useEffect(() => {
    $t('register', trackingCode, { allowDuplicate: true });
  }, []);

  /**
    If an articlePublishDate is provided we need to determine if the articlePublishDate
    is after the ENCODER_STOP_DATE (isCurrentArticlePublishedAfterEncoderStopDate)
    because then we wouldn't need to build the affiliate link in Ramen
  */
  let isCurrentArticlePublishedAfterEncoderStopDate;
  let affiliateUrl;
  if (articlePublishDate) {
    const fetchedEncoderStopDate = getDateTime(ENCODER_STOP_DATE);
    const fetchedCurrentArticlePublishDate = getDateTime(articlePublishDate);
    // eslint-disable-next-line max-len
    isCurrentArticlePublishedAfterEncoderStopDate = fetchedCurrentArticlePublishDate > fetchedEncoderStopDate;
    affiliateUrl = isCurrentArticlePublishedAfterEncoderStopDate
      ? productUrl
      : buildAffiliateLink({ url: productUrl, canonicalUrl });
  } else {
    affiliateUrl = productUrl;
  }
  const isWaffle = block === 'WaffleProduct';

  const isLongerThan8Chars = isLengthGreaterThan8(seller);
  const bemElemPriceBtn = `${block}__priceBtn`;
  const costStyles = [bemElemPriceBtn, additionalStyles.cost,
    { 'shop-button-info__cost-fit-text': isLongerThan8Chars }];

  const shouldShowStrikeThroughPricing = isOnSale({ salePrice, listPrice });

  const renderPrice = () => (
    <>
      {shouldShowStrikeThroughPricing && (
        <div className="shop-button-info__sale-cost">
          $
          {salePrice}
        </div>
      )}
      <div className={classNames('shop-button-info__list-cost', { 'strike fw5': shouldShowStrikeThroughPricing })}>
        $
        {listPrice}
      </div>
    </>
  );

  const body = (
    <>
      <span className={classNames(costStyles, {
      }, 'shop-button-info__cost')}
      >
        {renderPrice()}
      </span>
      <ShopButton
        additionalStyles={additionalStyles}
        block={block}
        isWaffle={isWaffle}
        listPrice={listPrice}
        name={name}
        seller={seller}
        shouldTrackClicks={false} // click already tracked here in parent
        trackingCode={trackingCode}
      />
    </>
  );

  const variationBody = (
    <ShopButton
      additionalStyles={additionalStyles}
      block={block}
      isWaffle={isWaffle}
      listPrice={listPrice}
      salePrice={salePrice}
      name={name}
      seller={seller}
      shouldTrackClicks={false} // click already tracked here in parent
      trackingCode={trackingCode}
      variation={variation}
    />
  );

  if (isLink) {
    const href = universalCheckoutEnabled ? productUrl : affiliateUrl;
    return (
      <Link
        data-testid="shop-button-info-link"
        data-product-id={productId}
        className={classNames(
          `${block}__shopButtons`,
          'shop-button-info__shop',
          {
            'shop-button-info__shop-fit-text': isLongerThan8Chars,
          },
          additionalStyles.shop,
        )}
        href={href}
        onClick={() => {
          $t('track', trackingCode, {
            action: 'itemClick',
            name,
            listPrice,
            seller,
          });
        }}
        rel="sponsored noopener noreferrer"
        target={isWaffle ? target : '_blank'}
        key={affiliateUrl}
      >
        { vertical === 'select' ? (
          <RunExperiment
            variantComponents={[variationBody]}
            campaignNumber={1407}
            cookieValue={variation}
          >
            {(body)}
          </RunExperiment>
        ) : (body)}
      </Link>

    );
  }

  return (
    <div
      className={classNames(
        `${block}__shopButtons`,
        'shop-button-info__shop',
        { 'shop-button-info__shop-fit-text': isLongerThan8Chars },
        additionalStyles.shop,
      )}
      key={affiliateUrl}
      data-testid="shop-button-info"
    >
      {body}
    </div>
  );
};

ShopButtonInfo.propTypes = {
  productId: PropTypes.string,
  name: PropTypes.string.isRequired,
  listPrice: PropTypes.string.isRequired,
  salePrice: PropTypes.string,
  articlePublishDate: PropTypes.string,
  productUrl: PropTypes.string.isRequired,
  seller: PropTypes.string,
  additionalStyles: PropTypes.shape({
    shop: PropTypes.string,
    cost: PropTypes.string,
    vendor: PropTypes.string,
    icon: PropTypes.string,
    vendorName: PropTypes.string,
  }),
  block: PropTypes.string,
  isLink: PropTypes.bool,
  vertical: PropTypes.string,
  target: PropTypes.string,
  trackingCode: PropTypes.string,
  cookies: PropTypes.instanceOf(Cookies),
};

ShopButtonInfo.defaultProps = {
  productId: undefined,
  seller: null,
  isLink: true,
  salePrice: '',
  articlePublishDate: '',
  additionalStyles: PropTypes.shape({
    shop: '',
    cost: '',
    vendor: '',
    icon: '',
    vendorName: '',
  }),
  block: 'ListicleProduct',
  vertical: null,
  target: '_blank',
  trackingCode: 'mbt_ecommerce_shop_button',
  cookies: null,
};

ShopButtonInfo.contextTypes = {
  canonicalUrl: PropTypes.string,
};


// eslint-disable-next-line import/no-default-export
export default withCookies(ShopButtonInfo);
